import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient,private toastr: ToastrService

  ) { }

  HttpGet<T>(url: string): Observable<T> {

    url = environment.apiServer + url;

    let httpHeaders = this.setHeader();

    // console.log('url=' + url);

    return this.httpClient.get<T>(url, { headers: httpHeaders })
      .pipe(
        catchError((err) => this.handleError(err)),
        finalize(() => {

        })
      );
  }

  HttpPost<T>(url: string, data: any): Observable<T> {

    url = environment.apiServer + url;

    let httpHeaders = this.setHeader();

    // console.log('url=' + url);

    return this.httpClient.post<T>(url, data, { headers: httpHeaders })

      .pipe(
        catchError((err) => this.handleError(err)),
        finalize(() => {
          
        })
      );
  }

  private setHeader(): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    const securityToken: string = ""
    let tokenString = '';

      httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json');

    return httpHeaders;
  }

  private handleError(error: HttpErrorResponse) {
    //this.toastr.error("發生例外錯誤，詳細請查看console log");
   console.log(error.error);
   if(error.error!=null &&error.error!="Internal Server Error"){

    //alert(error.error);

   }
   if(error.error.status="error"){

//alert(error.error.error);
    return throwError(error.error.error);
  
   }
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      //  alert('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
  
  }
}