import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInfo } from '../_models/UserInfo';

@Injectable({
  providedIn: 'root'
})
export class BureauGuardGuard implements CanActivate {

  constructor(private router: Router, private httpClient: HttpClient) {}

  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let guard = true;
    return this.httpClient
      .get<any>(`/backstage-api/getCurrentUser`)
      .pipe(
        map((data: UserInfo) => {
          if (!data.isAdmin) {
            alert("此帳號不具有管理員身份，無權限進入Ｇ寶石後台！");
            this.router.navigate(['/']);
            guard = false;
          } 
          return guard;
        })
      );
  }
}
