import { Component, OnInit } from '@angular/core';
import { BaseReseponseMission } from '../_models/BaseReseponseMission'

import { UserInfo } from '../_models/UserInfo';
import { HttpService } from '../_services/http.service';
import { BaseResponse } from '../_models/BaseResponse';
@Component({
  selector: 'app-site-parsent-main',
  templateUrl: './site-parsent-main.component.html',
  styleUrls: ['./site-parsent-main.component.css']
})
export class SiteParsentMainComponent implements OnInit {
  userInfodetail: UserInfo;
  BaseReseponseMission: BaseReseponseMission;
  baseResponseInfo: BaseResponse;
  constructor(private httpService: HttpService) { }
  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  ngOnInit() {
    this.getMission();
    this.getUserInfo();
  }
  public getMission() {
    this.BaseReseponseMission = null;
    let url = 'service/student/getMission';
    this.httpService.HttpGet(url).
      subscribe((response: BaseReseponseMission) => {
        this.BaseReseponseMission = response;
        console.log(this.BaseReseponseMission);
      }, err => {
      });
  }

  public getUserInfo() {
    this.userInfodetail = null;
    let url = 'service/getUserInfo';
    this.httpService.HttpGet(url).
      subscribe((response: UserInfo) => {
        this.userInfodetail = response;
        console.log(this.userInfodetail);
      }, err => {
      });
  }

  public allStartMission(){
    
  }
}
