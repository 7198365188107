import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserInfo } from '../_models/UserInfo';
import { HttpService } from '../_services/http.service';

@Component({
  selector: 'app-parsent-layout',
  templateUrl: './parsent-layout.component.html',
  styleUrls: ['./parsent-layout.component.css'],
  styles: [':host{width:100%}']
})
export class ParsentLayoutComponent {
  userInfodetail1: UserInfo;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService) { }

  ngOnInit() {
    this.getUserInfo();
  }
  public getUserInfo() {
    this.userInfodetail1 = null;
    let url = 'service/getUserInfo';
    this.httpService.HttpGet(url).
      subscribe((response: UserInfo) => {
        this.userInfodetail1 = response;

        console.log(this.userInfodetail1);

      }, err => {
      });

  }
  openNav() {
    document.getElementById("sidemenu").style.display = "inline-table";
  }
  
  logout() {
    //@ts-ignore
    if (!!window.ReactNativeWebView)
      // app的webview模式專用關閉網址
      window.location.replace('https://cooc.tp.edu.tw/app/close');
    else
      // 登出後回酷課雲首頁
      window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
  }
}
