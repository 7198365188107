export type UserData = {
  uuid?: string;
  user_name?: string;
  first_name?: string;
  last_name?: string;
  photo?: string;
  id_number?: string;
  email?: string;
  email_verified?: boolean;
  role?: string[];
  role_detail?: RoleDetail[];
  sso_role?: SSORole[];
};

export type RoleDetail = {
  role?: string;
  school_code?: string;
  school_name?: string;
  school_type?: string;
  source?: string;
};

export type SSORole = {
  source?: string;
  identity?: string;
  user_name?: string;
  first_name?: string;
  last_name?: string;
  id_number?: string;
  email?: string;
  email_verified?: boolean;
  last_login_time?: Date;
  role?: SSORoleDetail[];
};

export type SSORoleDetail = {
  role_type?: string;
  school_code?: string;
  school_name?: string;
  school_type?: string;
  school_grade_year?: number;
  school_class_name?: string;
  school_class_no?: string;
  school_seat_no?: number;
  school_user_id?: string;
  school_student_number?: string;
  school_position?: SchoolPosition[];
  child_detail?: ChildDetail;
};

export type SchoolPosition = {
  Dept?: string;
  DeptCode?: string;
  Unit?: string;
  UnitCode?: string;
  Title?: string;
  TitleCode?: string;
};

export type ChildDetail = {
  name?: string;
  photo?: string;
  identity?: string;
  id_number?: string;
};

/** 點數類型 */
export enum PointRecordType {
  /** G寶石 */
  GPoint = "G_POINT",
  /** P特幣 */
  PPoint = "P_POINT",
}

/** 條件代碼類型 */
export enum ConditionCodeType {
  /** 使用酷課雲 */
  USE_COOLCOURSE_CLOUD = "USE_COOLCOURSE_CLOUD",
  /** 使用掃碼功能 */
  USE_QR_SCANNER = "USE_QR_SCANNER",
  /** 查看校園訂閱公告 */
  VIEW_CAMPUS_SUBSCRIPTION_NOTICES = "VIEW_CAMPUS_SUBSCRIPTION_NOTICES",
  /** 前往學科影片 */
  GO_TO_SUBJECT_VIDEOS = "GO_TO_SUBJECT_VIDEOS",
  /** 掃碼報到 */
  SCAN_CHECKIN = "SCAN_CHECKIN",
  /** 自訂活動條件 */
  CUSTOMISE_ACTIVITY_CONDITION = "CUSTOMISE_ACTIVITY_CONDITION",
}

/** 活動參與角色 */
export enum ActivityRole {
  /** 學生 */
  STUDENT = "student",
  /** 家長 */
  PARENT = "parent",
  /** 老師 */
  TEACHER = "teacher",
}

/** 計算區間類型 */
export enum IntervalType {
  /** 每日 */
  DAILY = "daily",
  /** 每週 */
  WEEKLY = "weekly",
  /** 每月 */
  MONTHLY = "monthly",
  /** 每年 */
  YEARLY = "yearly",
  /** 一次性 */
  ONE_TIME = "one-time",
}

/** 活動參加者 */
export type ActivityUser = {
  /** 活動參加者 ID */
  id: string;
  /** 使用者 UUID */
  uuid: string;
  /** 姓名 */
  name: string;
};

/** 活動 */
export type Activity = {
  /** 活動 ID */
  id?: string;
  /** 活動名稱 */
  name?: string;
  /** 開始時間，須大於現在時間 */
  start_time?: Date;
  /** 結束時間，須大於開始時間 */
  end_time?: Date;
  /** 活動說明 */
  description?: string;
  /** 參與者角色 */
  participant_roles?: string[];
  /** 參與人數上限，0 表示無上限 */
  max_participants?: number;
  /** 需報名才能參加活動 */
  is_registration_required?: boolean;
  /** 當前活動狀態，狀態包含：1=啟用、0=停用 */
  status?: number;
  /** 活動是否被刪除 */
  is_deleted?: boolean;
};

/** 活動條件 */
export type ActivityCondition = {
  /** 活動條件 ID */
  id: string;
  /** 條件代碼 */
  condition_code: string;
  /** 關聯的活動 ID */
  activity_id: string;
  /** 計算區間 */
  interval_type: string;
  /** 區間計算次數上限 */
  interval_count_limit: number;
  /** 目標達成次數 （幾次才能獲點） */
  target_interval_completion_count: number;
  /** 完成次數上限 */
  completion_count_limit: number;
  /** 點數類型 */
  point_type: PointRecordType;
  /** 獲得點數，參與者完成任務一次可以獲得的點數 */
  point: number;
  /** 點數發放上限，表示整個活動能夠發放的點數最大值 */
  point_distribution_limit: number;
  /** 活動條件是否被刪除 */
  is_deleted?: boolean;
  /** 活動條件驗證碼 */
  verification_code?: string;
  /** 活動條件驗證碼描述 */
  verification_code_description?: string;
  /** 活動條件描述 */
  description?: string;
  /** 活動條件名稱 */
  condition_name?: string;
  /** 目前已發送出去的點數 */
  point_distribution_sum?: number;
};

/** 活動報名紀錄 */
export type ActivityRegistrationRecord = {
  /** 活動報名紀錄 ID */
  id: string;
  /** 活動 ID */
  activity_id: string;
  /** 使用者 ID */
  activity_user_id: string;
  /** 建立日期 */
  created_date: Date;
};

/** 活動紀錄 */
export type ActivityRecord = {
  /** 活動紀錄 ID */
  id: string;
  /** 活動 ID */
  activity_id: string;
  /** 活動條件 ID */
  activity_condition_id: string;
  /** 條件代碼 */
  condition_code: string;
  /** 使用者 ID */
  activity_user_id: string;
  /** 是否已結算 */
  settled: boolean;
  /** 建立日期 */
  created_date: Date;
};

/** 點數紀錄 */
export type PointRecord = {
  /** 點數紀錄 ID */
  id: string;
  /** 使用者 ID */
  activity_user_id: string;
  /** 點數類型 */
  point_type: PointRecordType;
  /** 獲得點數，允許負值 */
  point: number;
  /** 建立日期 */
  created_date: Date;
  /** 活動 ID，允許 NULL */
  activity_id?: string;
  /** 活動條件 ID，允許 NULL */
  activity_condition_id?: string;
  /** 條件代碼，允許 NULL */
  condition_code?: string;
  /** 兌換紀錄 ID，允許 NULL */
  prize_redemption_record_id?: string;
  /** 點數匯入排程 ID，允許 NULL */
  point_import_schedule_id?: string;
};

/** 點數總表紀錄 */
export type TotalPointsRecord = {
  /** 點數紀錄 ID */
  id: string;
  /** 使用者 ID */
  activity_user_id: string;
  /** 點數類型 */
  point_type: PointRecordType;
  /** 總點數 */
  total_points: number;
  /** 建立日期 */
  created_date: Date;
  /** 更新日期 */
  updated_date: Date;
};

/** 商品 */
export type Prize = {
  /** 商品 ID */
  id?: string;
  /** 商品名稱 */
  name?: string;
  /** 當前商品狀態，狀態包含：1=上架、0=下架 */
  status?: number;
  /** 商品說明 */
  description?: string;
  /** 兌換說明 */
  redemption_notes?: string;
  /** 扣除的點數類型 */
  deduction_point_type?: string;
  /** 售價 */
  price?: number;
  /** 庫存量 */
  inventory_count?: number;
  /** 每人兌換上限 */
  individual_redemption_limit?: number;
  /** 下架時間 */
  deactivation_time?: Date;
  /** 允許兌換的角色 */
  redeemable_roles?: string[];
  /** 商品圖片 */
  cover_url?: string;
  /** 序號有效期限 */
  serial_expiry_date?: Date;
  /** 建立日期 */
  created_date?: Date;
  /** 商品類別 */
  type?: string;
  /** 抽獎券開獎日期 */
  lottery_draw_date?: Date;
};

/** 商品序號 */
export type PrizeSerial = {
  /** 序號 ID */
  id: string;
  /** 關聯的商品 ID */
  prize_id: string;
  /** 序號驗證碼 */
  code: string;
  /** 序號網址 */
  url: string;
  /** 序號有效期限 */
  expiry_date: Date;
  /** 建立日期 */
  created_date: Date;
};

/** 兌換紀錄 */
export type PrizeRedemptionRecord = {
  /** 兌換紀錄 ID */
  id?: string;
  /** 使用者 ID */
  activity_user_id?: string;
  /** 關聯的商品 ID */
  prize_id?: string;
  /** 關聯的商品序號 ID，允許 NULL */
  prize_serial_id?: string;
  /** 兌換日期 */
  redemption_date?: Date;
  /** 建立日期 */
  created_date?: Date;
};

/** 商品查詢資料 */
export type PrizeQueryData = Prize & {
  /** 此商品已被兌換的數量 */
  prize_redemption_record_count?: number;
  /** 此商品已被當前使用者兌換的數量 */
  current_user_redeem_count?: number;
};

/** 兌換紀錄查詢資料 */
export type PrizeRedemptionRecordQueryData = PrizeRedemptionRecord &
  Prize &
  PrizeRedemptionRecord &
  LotteryPrizeSerial & {
    /** 商品名稱 */
    prize_name?: string;
    /** 商品價格 */
    prize_price?: number;
    /** 扣除的點數類型 */
    prize_deduction_point_type?: string;
    /** 序號驗證碼 */
    serial_code?: string;
    /** 序號網址 */
    serial_url?: string;
    /** 序號有效期限 */
    serial_expiry_date?: Date;
    /** 序號類型 */
    serial_type?: string;
    /** 商品是否被刪除 */
    prize_is_deleted?: boolean;
    /** 序號是否被刪除 */
    serial_is_deleted?: boolean;
    /** 序號狀態 */
    serial_status?: boolean;
    /** 已抽獎次數 */
    already_draw_times?: number;
  };

/** 樂透券序號 */
export type LotteryPrizeSerial = {
  /** 抽獎券序號的唯一識別符，使用SERIAL格式來自動產生 */
  id?: string;
  /** 抽獎券商品id（prize.id） */
  lottery_ticket?: string;
  /** 自產序號 */
  serial?: string;
  /** 序號有效期限 */
  expiry_date?: Date;
  /** 使用者Id */
  activity_user_id?: string;
  /** 抽獎券狀態 狀態包含：IS_WINNING(中獎) NOT_WINNING(未中獎) NOT_YET_DRAWN(尚未開獎) */
  status?: string;
  /** 建立日期 */
  created_date?: Date;
  /** 商品序號是否被刪除 */
  is_deleted?: boolean;
};

export type LotteryQueryResult = {
  /** 樂透券中獎後要兌換的獎品 */
  lotteryPrize?: Prize;
  /** 自己的那張樂透券的結過 */
  selfLotterySerialResult?: LotteryPrizeSerial;
  /** 中獎名單 */
  lotteryResultList?: LotteryResult[];
};

export type LotteryResult = LotteryPrizeSerial & {
  /** 姓名 */
  name: string;
  /** 身分證字號 */
  id_number: string;
  /** 學校名稱 */
  school_name: string;
};

// 中獎名單
export type NewLotteryResult = {
  /** 抽獎券開獎日期 */
  lottery_draw_date: Date;
  is_show: boolean;
  winner: LotteryResult[];
};

/** 活動條件查詢資料 */
export type ActivityConditionQueryData = ActivityCondition & {
  /** 參與者角色 */
  participant_roles: string[];
  /** 參與人數上限，0表示無上限 */
  max_participants: number;
  /** 須報名才能參加活動 */
  is_registration_required: boolean;
};

export type ActivityConditionResult = ActivityCondition & {
  /** 目前回合進行次數 */
  activity_record_count?: number;
  /** 目前活動完成回合數 */
  point_record_count?: number;
};

/** 活動查詢資料 */
export type ActivityQueryData = Activity & {
  /** 活動條件 */
  conditions?: ActivityConditionResult[];
  /** 活動報名Id */
  activity_registration_record_id?: number;
};

/** point_record 獲得紀錄 */
export type PointRecordHistoryQueryData = {
  /** 活動Id */
  activity_id: string;
  /** 活動名稱 */
  activity_name: string;
  /** 點數類型 */
  point_type: string;
  /** 獲得點數 */
  point: number;
  /** 建立日期 */
  created_date: Date;
};

/** 使用者點數資料 */
export type UserPointInfoQueryData = {
  /** 使用者 ID */
  activity_user_id?: string;
  /** 使用者名稱 */
  name?: string;
  /** 點數資料 */
  point_data?: { [key: string]: number };
};
