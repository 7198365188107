import { Component, OnInit, Renderer2 } from "@angular/core";
import { HttpService } from "../_services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { mergeMap, tap } from "rxjs/operators";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  LotteryQueryResult,
  LotteryResult,
  NewLotteryResult,
  PrizeQueryData,
  PrizeRedemptionRecord,
  PrizeRedemptionRecordQueryData,
} from "../models/activity";

import * as $ from "jquery";
import { LoadingService } from "../_services/loading.service";
import { EMPTY } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BsModelService } from "src/app/_services/bs-model.service";

@Component({
  selector: "app-prize-detail",
  templateUrl: "./prize-detail.component.html",
  styleUrls: ["./prize-detail.component.css"],
})
export class PrizeDetailComponent implements OnInit {
  get isApp() {
    // return this.bsModelService.isApp
    return true;
  }

  prize: PrizeQueryData = {};
  prizeRedemptionRecord: PrizeRedemptionRecordQueryData = {};
  prizeId = "";
  prizeRedemptionRecordId = "";
  prizeDescription: SafeHtml;
  prizeRedemptionNotes: SafeHtml;
  checkMessage = "";
  serialCategory = "";
  IS_WINNING = false;
  NOT_WINNING = false;
  NOT_YET_DRAWN = false;

  lotteryResult: LotteryQueryResult;
  lotteryResultShowStatus = "hide";
  lotteryResultShowList: LotteryResult[];
  winningRedemptionRecord: PrizeRedemptionRecord;

  // 總中獎人數
  lotteryResultNumber = 0;

  mode: string = "NOT_YET_REDEMPTION";

  lotteryResultList: NewLotteryResult[] = [];

  // 是否顯示完整名單
  showFullList: boolean[] = [false];

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private httpClient: HttpClient,
    private bsModelService: BsModelService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        mergeMap((params) => {
          this.loadingService.show();
          this.prizeRedemptionRecordId = params["prize_redemption_record_id"];
          // 還沒兌換
          if (!this.prizeRedemptionRecordId) {
            this.prizeId = params["prize_id"];
            return this.getPrizeDetail().pipe(
              mergeMap(() => this.getPrizeRedeemable())
            );
          }
          this.mode = "ALREADY_REDEMPTION";
          // 已經兌換後的詳細頁面
          return this.getPrizeRedemptionRecordDetail().pipe(
            mergeMap(() => this.getPrizeDetail()),
            tap(() => {
              this.checkMessage = "OK";
            })
          );
        }),
        tap(() => this.loadingService.hide())
      )
      .subscribe();
  }

  getPrizeDetail() {
    return this.httpService
      .HttpGet(`service/activity/getPrizeDetail?prize_id=${this.prizeId}`)
      .pipe(
        tap((response: { message: string; data: PrizeQueryData }) => {
          // console.log("2-prize",response.data)
          this.prize = response.data;

          this.prizeDescription = this.sanitizer.bypassSecurityTrustHtml(
            this.prize.description
          );
          this.prizeRedemptionNotes = this.sanitizer.bypassSecurityTrustHtml(
            this.prize.redemption_notes
          );
        })
      );
  }

  getPrizeRedeemable() {
    return this.httpService
      .HttpGet(
        `service/activity/redeemPrize?prize_id=${this.prizeId}&is_check=true`
      )
      .pipe(
        tap((response: { message: string }) => {
          this.checkMessage = response.message;
        })
      );
  }

  getPrizeRedemptionRecordDetail() {
    return this.httpService
      .HttpGet(
        `service/activity/getPrizeRedemptionRecordDetail?prize_redemption_record_id=${this.prizeRedemptionRecordId}`
      )
      .pipe(
        tap(
          async (response: {
            message: string;
            data: PrizeRedemptionRecordQueryData;
          }) => {
            this.prizeRedemptionRecord = response.data;
            this.prizeId = response.data.prize_id;
            this.serialCategory = this.prizeRedemptionRecord.type.split("_")[0];
            // console.log("1-prizeRedemptionRecord",response.data);
            // console.log("serialCategory",this.serialCategory);

            // 判斷如果是樂透券的話
            if (this.serialCategory === "LOTTERY") {
              let lotteryResult = await this.getLotteryResult().toPromise();

              // 近一步利用lottery_draw_date判斷是否開獎了 還沒開獎則更改狀態(NOT_YET_DRAWN)就好
              if (!this.prizeRedemptionRecord.already_draw_times) {
                this.NOT_YET_DRAWN = true;
                return;
              }
              // 反之則近一步取得中獎名單 與自己是否中獎

       
              // this.lotteryResultNumber =
              //   lotteryResult.data.lotteryResultList.length;
              // console.log("@@@", this.lotteryResult);
              // console.log("@@@", this.lotteryResultNumber);
              // 設定自己這張樂透券的抽獎結果
              switch (this.lotteryResult.selfLotterySerialResult.status) {
                case "IS_WINNING":
                  this.IS_WINNING = true;
                  break;
                case "NOT_WINNING":
                  this.NOT_WINNING = true;
                  break;
                case "NOT_YET_DRAWN":
                  this.NOT_YET_DRAWN = true;
                  break;
              }
            }
          }
        )
      );
  }

  getLotteryResult() {
    const params = new HttpParams().set(
      "prize_redemption_record_id",
      this.prizeRedemptionRecordId
    );
    return this.httpClient
      .get("service/activity/getLotteryResult", { params })
      .pipe(
        tap((response: { message: string; data: any }) => {
          this.lotteryResult = response.data;
          // this.lotteryResultShowList =
          //   this.lotteryResult.lotteryResultList.slice(0, 10);

          this.lotteryResultList = response.data.lotteryResultList;
          this.getWinnerNumber();

          // 是否展開全部的狀態array
          this.showFullList = new Array(this.lotteryResultList.length).fill(
            false
          );
        })
      );
  }

  // 取得隨機日期
  getRandomDate(startDate?: Date, endDate?: Date): Date {
    const start = startDate || new Date("2024-01-01");
    const end = endDate || new Date("2025-01-01");

    const date = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    const res =
      `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z` as any;
    return res;
  }

  public redeemPrize() {
    // 移除 Modal 背景
    const el = document.body.querySelector("#btn-close");
    const el2 = document.body.querySelector("#btn-close2");
    if (el) {
      $(el).trigger("click");
    }
    if (el2) {
      $(el2).trigger("click");
    }

    this.loadingService.show();

    // 已經在兌換盒內的內部券進行內部核銷時執行
    if (this.serialCategory === "INTERNAL") {
      this.httpService
        .HttpGet(
          `service/activity/reimbursement?prize_id=${this.prizeId}&code=${this.prizeRedemptionRecord.serial_code}`
        )
        .pipe(
          mergeMap((response: { message: string }) => {
            if (response.message !== "OK") {
              // alert(response.message);
              this.checkMessage = response.message;
              this.loadingService.hide();
              return EMPTY;
            }

            return this.getPrizeRedemptionRecordDetail().pipe(
              mergeMap(() => this.getPrizeDetail()),
              tap(() => {
                // const el = document.body.querySelector("#btn-close");
                // if (el) {
                //   $(el).trigger("click");
                // }

                this.loadingService.hide();

                // 前往兌換紀錄詳細頁
                this.router.navigate(
                  [
                    this.isApp
                      ? "/redemption-detail"
                      : "/redemption-detail-web",
                  ],
                  {
                    queryParams: {
                      prize_redemption_record_id: this.prizeRedemptionRecordId,
                    },
                  }
                );
              })
            );
          })
        )
        .subscribe();
    } else {
      // 獎勵兌換頁面按下立即兌換後會執行的地方，因此不管哪一種券或是獎品都會執行這裡之後才會進入兌換盒
      this.httpService
        .HttpGet(`service/activity/redeemPrize?prize_id=${this.prizeId}`)
        .pipe(
          mergeMap(
            (response: { message: string; data: PrizeRedemptionRecord }) => {
              if (!response.data) {
                // alert(response.message);
                this.checkMessage = response.message;
                this.loadingService.hide();
                return EMPTY;
              }
              // console.log(response.data);
              this.prizeRedemptionRecordId = response.data.id;
              return this.getPrizeRedemptionRecordDetail().pipe(
                mergeMap(() => this.getPrizeDetail()),
                tap(() => {
                  // const el = document.body.querySelector("#btn-close");
                  // if (el) {
                  //   $(el).trigger("click");
                  // }

                  this.loadingService.hide();

                  // 前往兌換紀錄詳細頁
                  this.router.navigate(
                    [
                      this.isApp
                        ? "/redemption-detail"
                        : "/redemption-detail-web",
                    ],
                    {
                      queryParams: {
                        prize_redemption_record_id:
                          this.prizeRedemptionRecordId,
                      },
                    }
                  );
                })
              );
            }
          )
        )
        .subscribe();
    }
  }

  public gotoUrl() {
    const { serial_url } = this.prizeRedemptionRecord;
    if (!serial_url) {
      return;
    }
    this.loadingService.show();
    window.open(serial_url, "_system");
    this.loadingService.hide();
  }

  changeLotteryResultShowStatus(status) {
    this.lotteryResultShowStatus = status;
    if (this.lotteryResultShowStatus === "hide") {
      this.lotteryResultShowList = this.lotteryResult.lotteryResultList.slice(
        0,
        10
      );
    } else {
      this.lotteryResultShowList = this.lotteryResult.lotteryResultList;
    }
  }

  async gotoPrize() {
    await this.getLotteryWinningRedemptionRecordId().toPromise();
    // 前往兌換紀錄詳細頁
    this.router.navigate(
      [this.isApp ? "/redemption-detail" : "/redemption-detail-web"],
      {
        queryParams: {
          prize_redemption_record_id: this.winningRedemptionRecord.id,
        },
      }
    );
  }

  getLotteryWinningRedemptionRecordId() {
    // console.log("in");
    const params = new HttpParams().set(
      "prize_redemption_record_id",
      this.prizeRedemptionRecordId
    );
    return this.httpClient
      .get("service/activity/getLotteryWinningRedemptionRecordId", { params })
      .pipe(
        tap((response: { message: string; data: any }) => {
          this.winningRedemptionRecord = response.data;
        })
      );
  }

  isShowWinner(data: NewLotteryResult) {
    return new Date(data.lottery_draw_date) <= new Date();
  }

  getWinnerNumber() {
    const arr = this.lotteryResultList.filter(
      (v) => new Date(v.lottery_draw_date) <= new Date()
    );

    let winnerNum = 0;

    for (let data of arr) {
      winnerNum += data.winner.length;
    }

    console.log("winnerNum", winnerNum);

    this.lotteryResultNumber = winnerNum;
  }

  getDateString(data: Date) {
    const date = new Date(data);
    return `${date.getFullYear()}年 ${this.getTwoDigital(
      date.getMonth() + 1
    )}月 ${this.getTwoDigital(date.getDate())}日 ${this.getTwoDigital(
      date.getHours()
    )}時 ${this.getTwoDigital(date.getMinutes())}分`;
  }

  getTwoDigital(data: number) {
    return String(data).padStart(2, "0");
  }

  goToPrize() {
    this.router.navigate(["/prize-redemption-history"]);
  }

  nameHide(name) {
    const isChinese = /[\u3400-\u9FBF]/.test(name);
    if (isChinese) {
      return name.length > 1 ? name[0] + "Ｏ" + name.substring(2) : name;
    } else {
      let words = name.split(/\s+/);
      if (words.length > 1) {
        words[1] = "Ｏ";
        return words.join(" ");
      } else {
        return name;
      }
    }
  }
}
