import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserInfo } from '../../_models/UserInfo';
import { HttpService } from '../../_services/http.service';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  styles: [':host{width:100%}']
})
export class LayoutComponent {
  userInfodetail: UserInfo;
  currentRole: any = {};
  currentRoleChanged$ = new ReplaySubject(1);
  roleList: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService
  ) {
  }

  ngOnInit() {
  }

  public setUserInfo(userInfo: UserInfo) {
    this.userInfodetail = userInfo;
    let role = [];
    if (userInfo.isStudent) {
      role.push({
        roleType: 'student'
        , ldapUUID: ''
        , name: userInfo.name
        , desc: ''
        , isTrial: false
      });
    }
    if (userInfo.isTeacher && userInfo.hrClass) {
      role = role.concat(userInfo.hrClass.map(hrClass => Object.assign({
        roleType: 'teacher'
        , ldapUUID: hrClass.ldapUUID
        , name: userInfo.name
        , desc: hrClass.className + '班導師'
        , detail: hrClass
        , isTrial: false
      })));
    }
    if (userInfo.isParent) {
      role = role.concat(userInfo.child.map(child => Object.assign({
        roleType: 'parent'
        , ldapUUID: child.ldapUUID
        , name: child.name + '的家長'
        , desc: child.schoolName
        , detail: child
        , isTrial: false
      })));
    }
    // 系統管理者身分
    if (userInfo.isAdmin) {
      role.push({
        roleType: 'admin'
        , ldapUUID: ''
        , name: userInfo.name
        , desc: '系統管理者'
        , isTrial: false
      });
    }
    // 非導師、家長、學生，給個預設身分
    if (!role.filter((value) => value.roleType != 'admin').length) {
      role.push({
        roleType: 'student'
        , ldapUUID: ''
        , name: userInfo.name
        , desc: '一般帳號'
        , isTrial: false
      });
    }

    this.roleList = role;
    if (this.currentRole && !this.currentRole.isTrial)
      this.setCurrentRole(role.find(r => r.roleType == this.currentRole.roleType && r.name == this.currentRole.name) || role[0]);
  }

  setCurrentRole(role) {
    console.log("role", role);
    role.isTrial = false;
    this.currentRole = role;
    this.currentRoleChanged$.next();
  }

  setTrialMode() {
    this.currentRole.isTrial = true;
    this.currentRoleChanged$.next();
  }

  openNav() {
    document.getElementById("sidemenu").style.display = "inline-table";
  }
  logout() {
    //@ts-ignore
    if (!!window.ReactNativeWebView)
      // app的webview模式專用關閉網址
      window.location.replace('https://cooc.tp.edu.tw/app/close');
    else
      // 登出後回酷課雲首頁
      window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
  }
}
