import { Router, ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { UserInfo } from '../_models/UserInfo';
import { HttpService } from '../_services/http.service';

@Component({
  selector: 'app-site-parsent-layout',
  templateUrl: './site-parsent-layout.component.html',
  styleUrls: ['./site-parsent-layout.component.css']
})
export class SiteParsentLayoutComponent {
  userInfodetail: UserInfo;
  constructor(private router: Router,
    route: ActivatedRoute,
     private httpService: HttpService) {  this.getUserInfo();}

  ngOnInit() {
  }
  public getUserInfo( ){
    this.userInfodetail=null;
    let url = 'service/getUserInfo';
    this.httpService.HttpGet(url).
    subscribe((response: UserInfo) => {
      this.userInfodetail=response;
  
      console.log(this.userInfodetail);
  
    }, err => {
    });
  
   }
   closeNav() {
    document.getElementById("sidemenu").style.display = "none";
  }
}
