import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';

import { LogoutComponent } from './shard/logout/logout.component';

import { MainComponent } from './main/main.component';
import { ActivityDetailComponent } from './activity-detail/activity-detail.component';
import { PointRecordHistoryComponent } from './point-record-history/point-record-history.component';
import { ShopsItemComponent } from './shops-item/shops-item.component';
import { ShopDetailComponent } from './shop-detail/shop-detail.component';
import { PrizeDetailComponent } from './prize-detail/prize-detail.component';
import { PrizeRedemptionHistoryComponent } from './prize-redemption-history/prize-redemption-history.component';
import { BureauGuardGuard } from './guard/bureau-guard.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: SiteLayoutComponent,
        children: [
          { path: '', redirectTo: 'Main', pathMatch: 'full' },
          { path: 'Main', component: MainComponent },
          {
            path: 'student',
            loadChildren: './student/student.module#StudentModule',
          },
          { path: 'shop', loadChildren: './shop/shop.module#ShopModule' },
          {
            path: 'role-shop',
            loadChildren: './role-shop/role-shop.module#RoleShopModule',
          },
          {
            path: 'act-record',
            loadChildren: './act-record/act-record.module#ActRecordModule',
          },
          {
            path: 'daily-record',
            loadChildren:
              './daily-record/daily-record.module#DailyRecordModule',
          },
          {
            path: 'shop-record',
            loadChildren: './shop-record/shop-record.module#ShopRecordModule',
          },
          { path: 'shop-detail', component: ShopDetailComponent },
          {
            path: 'point-record-history',
            component: PointRecordHistoryComponent,
          },
          {
            path: 'prize-redemption-history',
            component: PrizeRedemptionHistoryComponent,
          },
          {
            path: 'parent-daily-record',
            loadChildren:
              './parent-daily-record/parent-daily-record.module#ParentDailyRecordModule',
          },
          {
            path: 'parsent-act-record',
            loadChildren:
              './parsent-act-record/parsent-act-record.module#ParsentActRecordModule',
          },
          {
            path: 'admin-daily',
            loadChildren: './admin-daily/admin-daily.module#AdminDailyModule',
          },
          {
            path: 'admin-act',
            loadChildren: './admin-act/admin-act.module#AdminActModule',
          },
          {
            path: 'admin-shop',
            loadChildren: './admin-shop/admin-shop.module#AdminShopModule',
          },
          {
            path: 'admin-count',
            loadChildren: './admin-count/admin-count.module#AdminCountModule',
          },
          { path: 'activity-detail-web', component: ActivityDetailComponent },
          { path: 'prize-detail-web', component: PrizeDetailComponent },
          { path: 'redemption-detail-web', component: PrizeDetailComponent },
        ],
      },
      {
        path: 'backstage',
        canActivate: [BureauGuardGuard],
        loadChildren: './backstage/backstage.module#BackstageModule',
      },
    ],
  },
  { path: 'activity-detail', component: ActivityDetailComponent },
  { path: 'prize-detail', component: PrizeDetailComponent },
  { path: 'redemption-detail', component: PrizeDetailComponent },
  { path: 'shop-item', component: ShopsItemComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  declarations: [],
})
export class AppRoutingModule {}
