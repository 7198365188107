import { Component, OnInit } from "@angular/core";
import { HttpService } from "../_services/http.service";
import { ActivatedRoute } from "@angular/router";
import { switchMap, tap } from "rxjs/operators";
import {
  ActivityConditionResult,
  ActivityQueryData,
  ConditionCodeType,
  IntervalType,
  PointRecordType,
} from "../models/activity";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { LoadingService } from "../_services/loading.service";
@Component({
  selector: "app-activity-detail",
  templateUrl: "./activity-detail.component.html",
  styleUrls: ["./activity-detail.component.css"],
})
export class ActivityDetailComponent implements OnInit {
  get totalPoints() {
    if (
      !Array.isArray(this.activity.conditions) ||
      this.activity.conditions.length < 1
    ) {
      return 0;
    }

    return this.activity.conditions
      .map(({ point }) => point)
      // .map(({ point, completion_count_limit }) => point * completion_count_limit)
      .reduce((a, b) => a + b, 0);
  }

  get activityStatus() {
    const { conditions } = this.activity;
    if (!conditions) {
      return '活動進行中';
    }

    return conditions.every((condition) => condition.point_record_count > 0) ? '活動已完成' : '活動進行中';
  }

  activityDescription: SafeHtml;

  activityId = "";

  // activityStatus = "活動已完成"

  activity: ActivityQueryData = {};

  constructor(
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        tap(() => this.loadingService.show()),
        switchMap((params) => {
          this.activityId = params["activity_id"];
          return this.getActivityDetail();
        }),
        tap(() => this.loadingService.hide())
      )
      .subscribe();
  }

  getActivityDetail() {
    return this.httpService
      .HttpGet(
        `service/activity/getActivityDetail?activity_id=${this.activityId}`
      )
      .pipe(
        tap((response: { message: string; data: ActivityQueryData }) => {
          this.activity = response.data;
          this.activityDescription = this.sanitizer.bypassSecurityTrustHtml(
            this.activity.description
          );
        })
      );
  }

  public getIntervalType(interval_type: string) {
    switch (interval_type) {
      default:
      case IntervalType.DAILY:
        return "每日";
      case IntervalType.WEEKLY:
        return "每週";
      case IntervalType.MONTHLY:
        return "每月";
      case IntervalType.YEARLY:
        return "每年";
    }
  }

  public getConditionCodeType(condition: ActivityConditionResult) {
    const { 
      condition_code, 
      // verification_code, 
      // verification_code_description 
    } = condition;

    // if (verification_code && verification_code_description) {
    //   return verification_code_description;
    // }

    if (condition.condition_name) {
      return condition.condition_name;
    }

    switch (condition_code) {
      default:
      case ConditionCodeType.USE_COOLCOURSE_CLOUD:
        return "經常來訪酷課雲";
      case ConditionCodeType.USE_QR_SCANNER:
        return "找找掃碼功能";
      case ConditionCodeType.VIEW_CAMPUS_SUBSCRIPTION_NOTICES:
        return "查看校園訂閱公告";
      case ConditionCodeType.GO_TO_SUBJECT_VIDEOS:
        return "前往學科影片";
      case ConditionCodeType.CUSTOMISE_ACTIVITY_CONDITION:
        return "自訂活動條件";
    }
  }

  public getPointRecordType(point_type: string) {
    switch (point_type) {
      default:
      case PointRecordType.GPoint:
        return "G寶石";
      case PointRecordType.PPoint:
        return "P特幣";
    }
  }

  public getStatus(activity: ActivityQueryData) {
    const { conditions } = activity;
    if (!Array.isArray(conditions) || conditions.length < 1) {
      return "活動進行中";
    }

    return conditions.every(({ point_record_count }) => point_record_count > 0) ? '活動已完成' : '活動進行中';

    // const { completion_count_limit, point_record_count } = conditions[0];
    // return completion_count_limit === point_record_count
    //   ? "活動已完成"
    //   : "活動進行中";
  }

  public getConditionDescription(condition: ActivityConditionResult) {
    const {
      description,
      interval_type,
      interval_count_limit,
      target_interval_completion_count,
      point,
      point_type,
      completion_count_limit,
    } = condition;

    if (description) {
      return description;
    }

    return `達成條件：於活動期間，${this.getIntervalType(
      interval_type
    )}完成指定事項${interval_count_limit}次，累計
    ${target_interval_completion_count} 次視為任務達成。完成1次任務發放 ${point}
    顆${this.getPointRecordType(point_type)}，最多獲得 ${
      completion_count_limit > 0 ? completion_count_limit * point : '無限制'
    }
    顆${this.getPointRecordType(point_type)}`;
  }

  public getConditionCompleteness(condition: ActivityConditionResult) {
    const {
      completion_count_limit,
      point_record_count,
      target_interval_completion_count,
      activity_record_count,
      interval_count_limit,
    } = condition;

    return `已完成次數：(${
      point_record_count * target_interval_completion_count +
      activity_record_count
    } / ${target_interval_completion_count})`;

    // // 如果 completion_count_limit 為零表示不限制完成次數，僅顯示已完成次數
    // if (completion_count_limit === 0) {
    //   return `已完成回合：${point_record_count}次`
    // }

    // return `已完成回合：(${
    //   completion_count_limit === point_record_count
    //     ? completion_count_limit
    //     : point_record_count
    // } /
    // ${completion_count_limit})次`;
  }

  //該任務的點數是否發送完畢
  runoutPoint(condition: ActivityConditionResult): boolean {
    const points = condition.point_distribution_limit;
    const used = condition.point_distribution_sum || 0;

    return used >= points;
  }

  public getConditionStatus(condition: ActivityConditionResult) {
    const { completion_count_limit, point_record_count } = condition;

    return point_record_count > 0 ? "已完成" : "未完成";
    // if (completion_count_limit > point_record_count) {
    //   // 只要有任何一個為未完成，則代表活動進行中
    //   this.activityStatus = "活動進行中"
    //   return "未完成";
    // } else {
    //   return "已完成";
    // }
  }

  public getStar(condition: ActivityConditionResult): SafeHtml {
    if (!condition) {
      return this.sanitizer.bypassSecurityTrustHtml("");
    }

    const {
      completion_count_limit,
      point_record_count,
      activity_record_count,
      target_interval_completion_count,
    } = condition;

    // if (activity_record_count < 1 || (completion_count_limit > 0 && completion_count_limit === point_record_count)) {
    //   return this.sanitizer.bypassSecurityTrustHtml("");
    // }

    const fullIcon = `<img style="width: 20px; height: 20px;margin-right:2.5px;" src="assets/img/checked.png">`;
    const emptyIcon = `<img style="width: 20px; height: 20px;margin-right:2.5px;" src="assets/img/unchecked.png">`;

    // // 如果已經結束任務條件，則僅返回fullStar
    // if (completion_count_limit === point_record_count) {
    //   return this.sanitizer.bypassSecurityTrustHtml(
    //     Array(target_interval_completion_count).fill(fullIcon).join("")
    //   );
    // }

    //未有得點紀錄
    if (!point_record_count) {
      return this.sanitizer.bypassSecurityTrustHtml(
        `${Array(activity_record_count).fill(fullIcon).join("")}${Array(
          target_interval_completion_count - activity_record_count
        )
          .fill(emptyIcon)
          .join("")}`
      );
    }

    return this.sanitizer.bypassSecurityTrustHtml(
      `${Array(
        point_record_count * target_interval_completion_count +
          activity_record_count
      )
        .fill(fullIcon)
        .join("")}`
    );
  }
}
