import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserInfo } from '../_models/UserInfo';
import { BaseResponse } from '../_models/BaseResponse';
import { HttpService } from '../_services/http.service';
import { ShopDetailResponse } from '../_models/ShopDetailResponse';

@Component({
  selector: 'app-shop-detail',
  templateUrl: './shop-detail.component.html',
  styleUrls: ['./shop-detail.component.css']
})
export class ShopDetailComponent implements OnInit {
  ShopDetailResponse: ShopDetailResponse[];
  BaseResponseItem: BaseResponse;
  InfoId: number;
  radioSel: any;
  radioSelectedString: string;
  userInfodetail: UserInfo;
  empList: ShopDetailResponse;
  private productId: number;

  constructor(
    private router: Router,
    private routerInfo: ActivatedRoute,
    private httpService: HttpService,

    
  ) {
    let getUserInfoResp;
  }
  
  ngOnInit() {
    this.getUserInfo();

    //  this.productId=this.routerInfo.snapshot.queryParams["id"];

    let InfoId = this.routerInfo.snapshot.paramMap.get('InfoId');
    console.log("ID")
    this.shopinfoGet(InfoId);
  }
  public getUserInfo() {
    this.userInfodetail = null;
    let url = 'service/getUserInfo';
    this.httpService.HttpGet(url).
      subscribe((response: UserInfo) => {
        this.userInfodetail = response;

        console.log(this.userInfodetail);

      }, err => {
      });

  }
  
  openNav() {
    document.getElementById("sidemenu").style.display = "inline-table";
  }
  logout() {
    //@ts-ignore
    if (!!window.ReactNativeWebView)
      // app的webview模式專用關閉網址
      window.location.replace('https://cooc.tp.edu.tw/app/close');
    else
      // 登出後回酷課雲首頁
      window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
  }
  public buyshopItem(id) {
    let buyItemUrl = "";
    buyItemUrl = "service/student/buyItemG?id=";
    buyItemUrl = buyItemUrl + id;
    console.log(buyItemUrl);
    this.httpService.HttpGet(buyItemUrl).
      subscribe((response: BaseResponse) => {
        this.BaseResponseItem = response;
        if (this.BaseResponseItem.status == "success") {
          window.location.reload();
          alert("購買成功");
        }
        else {
          window.location.reload();
          alert(this.BaseResponseItem.message)

        }
      }, err => {
      });
  }
  public shopinfoGet(id: string) {
    this.ShopDetailResponse = null;
    let url = 'service/student/getSellingMenuG';
    this.httpService.HttpGet(url).
      subscribe((response: ShopDetailResponse[]) => {
        this.ShopDetailResponse = response;
        console.log(this.ShopDetailResponse)
        console.log(id);
        this.empList = this.ShopDetailResponse.find(x => x.id == id);
        this.radioSelectedString = JSON.stringify(this.empList);

        console.log(this.empList)


        // for(var i=0; i<this.ShopDetailResponse.description.length;i++)
        // {

        //  this.empList.push(this.ShopDetailResponse[i]);
        // }
        // this.radioSel =  this.empList.find(x=>x.id===id);
        // this.radioSelectedString = JSON.stringify(this.radioSel);
        //console.log(  this.radioSelectedString);

      }, err => {
      });

  }
}
