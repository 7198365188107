import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BsModelService {
  private title = new BehaviorSubject<string>('');
  private subTitle = new BehaviorSubject<string>('');
  private prizeId = new BehaviorSubject<string>('');
  private acticitId = new BehaviorSubject<string>('');
  private apiResponseCode = new BehaviorSubject<string>('');

  constructor() { }

  title$ = this.title.asObservable();
  subTitle$ = this.subTitle.asObservable();
  prizeId$ = this.prizeId.asObservable();
  acticitId$ = this.acticitId.asObservable();
  apiResponseCode$ = this.apiResponseCode.asObservable();

  setDeleteModelValue(title: string, subTitle: string, prizeId: string, acticitId: string) {
    this.title.next(title);
    this.subTitle.next(subTitle);
    this.prizeId.next(prizeId);
    this.acticitId.next(acticitId);
  }

  setDeleteAPIResponseCode(apiResponseCode: string) {
    this.apiResponseCode.next(apiResponseCode);
  }

  openDeleteModel() {
    ($("#deleteModal") as any).modal("show");
  }

  colseDeleteModel() {
    ($("#deleteModal") as any).modal("hide");
  }
}
