import { Component, OnInit } from "@angular/core";
import { HttpService } from "../_services/http.service";
import {
  PointRecordHistoryQueryData,
  PointRecordType,
} from "../models/activity";
import { tap } from "rxjs/operators";
@Component({
  selector: "app-point-record-history",
  templateUrl: "./point-record-history.component.html",
  styleUrls: ["./point-record-history.component.css"],
})
export class PointRecordHistoryComponent implements OnInit {
  get pointRecordGroupData(): Array<{ dateTime: string, records: PointRecordHistoryQueryData[] }> {
    const returnedData: Array<{ dateTime: string, records: PointRecordHistoryQueryData[] }> = [];
    
    this.pointRecords.sort((a, b) => +new Date(b.created_date) - +new Date(a.created_date))
      .forEach((pointRecord) => {
        const date = new Date(pointRecord.created_date);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const dateTime = `${year}/${month}`;
        
        let data = returnedData.find(g => g.dateTime === dateTime);
        if (!data) {
          data = { dateTime, records: [] };
          returnedData.push(data);
        }
        
        data.records.push(pointRecord);
      });
    
    return returnedData;
}

  pointRecords: PointRecordHistoryQueryData[] = [];

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.getPointRecordHistory().subscribe();
  }

  getPointRecordHistory() {
    return this.httpService
      .HttpGet("service/activity/getPointRecordHistory")
      .pipe(
        tap(
          (response: {
            message: string;
            data: PointRecordHistoryQueryData[];
          }) => {
            // debugger;
            this.pointRecords = response.data;
          }
        )
      );
  }

  public getPointType(point_type: string) {
    switch (point_type) {
      default:
      case PointRecordType.GPoint:
        return "G";
      case PointRecordType.PPoint:
        return "P";
    }
  }

  public getDate(date: string) {
    return new Date(date);
  }
}
