import { Component, OnInit } from "@angular/core";
import { HttpService } from "../_services/http.service";
import { SiteLayoutComponent } from "../_layout/site-layout/site-layout.component";
import { mergeMap, tap } from "rxjs/operators";
import { ActivityConditionResult, ActivityQueryData } from "../models/activity";
import { LoadingService } from "../_services/loading.service";
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit {
  get isApp() {
    //@ts-ignore
    return !!window.ReactNativeWebView;
  }

  activityList: ActivityQueryData[] = [];

  constructor(
    private loadingService: LoadingService,
    private httpService: HttpService,
    public siteLayoutComponent: SiteLayoutComponent
  ) {}

  ngOnInit() {
    this.loadingService.show();
    this.siteLayoutComponent.currentRoleChanged$
      .pipe(mergeMap(() => this.getActivityList()))
      .subscribe(() => this.loadingService.hide());
  }

  getActivityList() {
    return this.httpService
      .HttpGet("service/activity/getActivityList")
      .pipe(
        tap(
          (response: { message: string; data: ActivityQueryData[] }) =>
            (this.activityList = response.data)
        )
      );
  }

  public getStatus(activity: ActivityQueryData) {
    const { conditions } = activity;
    if (!Array.isArray(conditions) || conditions.length < 1) {
      return "活動進行中";
    }

    return conditions.every((condition) => condition.point_record_count > 0) ? '活動已完成' : '活動進行中';
    // let activityStatus = "活動已完成";

    // conditions.forEach((condition) => {
    //   if (condition.completion_count_limit > condition.point_record_count) {
    //     activityStatus = "活動進行中";
    //   }
    // });
    // console.log("活動已完成");
    // return activityStatus;
  }

  public getTotalPointsOnce(activityConditions: ActivityConditionResult[]) {
    if (!Array.isArray(activityConditions) || activityConditions.length < 1) {
      return 0;
    }

    return activityConditions
      .map(({ point }) => point)
      .reduce((a, b) => a + b, 0);
  }

  public getTotalPoints(activityConditions: ActivityConditionResult[]) {
    if (!Array.isArray(activityConditions) || activityConditions.length < 1) {
      return 0;
    }

    return activityConditions
      .map(
        ({ point, completion_count_limit }) => point * completion_count_limit
      )
      .reduce((a, b) => a + b, 0);
  }
}
