import { Router, ActivatedRoute } from "@angular/router";
import { Component } from "@angular/core";
import { UserInfo } from "../../_models/UserInfo";
import { HttpService } from "../../_services/http.service";
import { ReplaySubject } from "rxjs";
import { UserPointInfoQueryData } from "src/app/models/activity";
import { mergeMap, tap } from "rxjs/operators";

@Component({
  selector: "app-site-layout",
  templateUrl: "./site-layout.component.html",
  styleUrls: ["./site-layout.component.css"],
})
export class SiteLayoutComponent {
  userInfodetail: UserInfo;
  currentRole: any = {};
  currentRoleChanged$ = new ReplaySubject(1);
  roleList: any[];

  //#region Martin
  userPointInfo: UserPointInfoQueryData = {};
  //#endregion

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService
  ) {}

  ngOnInit() {

    this.currentRoleChanged$
    .pipe(
      mergeMap(() => this.getUserPointInfo())
    )
    .subscribe(() => {
      // if (this.currentRole.roleType == "parent") {
      //   this.currentRole.gPoint = "--";
      //   this.currentRole.pPoint = "--";
      // }

      // // 身分、模式改變時一律回第一頁
      // if (this.currentRole.roleType == "admin") {
      //   this.router.navigate(["/admin-daily"], {
      //     relativeTo: this.route,
      //     skipLocationChange: false,
      //   });
      // }
      // else {
      //   this.router.navigate(["/"], {
      //     relativeTo: this.route,
      //     skipLocationChange: false,
      //   });
      // }
    });

    this.getUserInfo();
  }

  public getUserPointInfo() {
    return this.httpService
      .HttpGet("service/activity/getUserPointInfo")
      .pipe(
        tap(
          (response: { message: string; data: UserPointInfoQueryData }) =>
            (this.userPointInfo = response.data)
        )
      );
  }

  public getUserInfo() {
    let url = "service/getUserInfo";
    this.httpService.HttpGet(url).subscribe(
      (response: UserInfo) => {
        this.userInfodetail = response;
        let role = [];
        if (this.userInfodetail.isStudent) {
          role.push({
            roleType: "student",
            ldapUUID: "",
            name: this.userInfodetail.name,
            desc: "",
            isTrial: false,
          });
        }
        if (this.userInfodetail.isTeacher && this.userInfodetail.hrClass) {
          role = role.concat(
            this.userInfodetail.hrClass.map((hrClass) =>
              Object.assign({
                roleType: "teacher",
                ldapUUID: hrClass.ldapUUID,
                name: this.userInfodetail.name,
                desc: hrClass.className + "班導師",
                detail: hrClass,
                isTrial: false,
              })
            )
          );
        }
        if (this.userInfodetail.isParent) {
          role = role.concat(
            this.userInfodetail.child.map((child) =>
              Object.assign({
                roleType: "parent",
                ldapUUID: child.ldapUUID,
                name: child.name + "的家長",
                desc: child.schoolName,
                detail: child,
                isTrial: false,
              })
            )
          );
        }
        // 系統管理者身分
        if (!this.isApp() && this.userInfodetail.isAdmin) {
          role.push({
            roleType: "admin",
            ldapUUID: "",
            name: this.userInfodetail.name,
            desc: "系統管理者",
            isTrial: false,
          });
        }
        // 非導師、家長、學生，給個預設身分
        if (!role.filter((value) => value.roleType != "admin").length) {
          role.push({
            roleType: "student",
            ldapUUID: "",
            name: this.userInfodetail.name,
            desc: "一般帳號",
            isTrial: false,
          });
        }

        this.roleList = role;
        if (this.currentRole && !this.currentRole.isTrial)
          this.setCurrentRole(
            role.find(
              (r) =>
                r.roleType == this.currentRole.roleType &&
                r.name == this.currentRole.name
            ) || role[0]
          );
      },
      (err) => {}
    );
  }

  closeNav() {
    document.getElementById("sidemenu").style.display = "none";
  }

  toTrialMode() {
    this.setTrialMode();
  }
  onSelect() {
    this.router.navigate(["/shop"], { relativeTo: this.route });
  }

  setCurrentRole(role) {
    console.log("role", role);
    role.isTrial = false;
    this.currentRole = role;
    this.currentRoleChanged$.next();
  }

  setTrialMode() {
    this.currentRole.isTrial = true;
    this.currentRoleChanged$.next();
  }

  openNav() {
    document.getElementById("sidemenu").style.display = "inline-table";
  }
  logout() {
    //@ts-ignore
    if (!!window.ReactNativeWebView)
      // app的webview模式專用關閉網址
      window.location.replace("https://cooc.tp.edu.tw/app/close");
    // 登出後回酷課雲首頁
    else
      window.location.replace(
        "/service/logout?next=" + encodeURIComponent("https://cooc.tp.edu.tw/")
      );
  }

  isApp() {
    //@ts-ignore
    return !!window.ReactNativeWebView;
    // return true;
  }
}
