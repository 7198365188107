import { Component, OnInit } from "@angular/core";
import { HttpService } from "../_services/http.service";
import { mergeMap, tap } from "rxjs/operators";
import {
  PointRecordType,
  PrizeRedemptionRecordQueryData,
  UserPointInfoQueryData,
} from "../models/activity";
import { LoadingService } from "../_services/loading.service";
@Component({
  selector: "app-prize-redemption-history",
  templateUrl: "./prize-redemption-history.component.html",
  styleUrls: ["./prize-redemption-history.component.css"],
})
export class PrizeRedemptionHistoryComponent implements OnInit {
  get isApp() {
    //@ts-ignore
    // return !!window.ReactNativeWebView;
    return true;
  }

  get prizeRedemptionRecordGroupDataForG(): Array<{ dateTime: string, records: PrizeRedemptionRecordQueryData[] }> {
    if (
      !Array.isArray(this.prizeRedemptionRecords) ||
      this.prizeRedemptionRecords.length < 1
    ) {
      return [];
    }

    const returnedData: Array<{ dateTime: string, records: PrizeRedemptionRecordQueryData[] }> = [];
    this.prizeRedemptionRecords
      .filter(
        (record) => record.prize_deduction_point_type === PointRecordType.GPoint
      )
      .sort((a, b) => {
        return +new Date(b.created_date) - +new Date(a.created_date);
      })
      .forEach((pointRecord) => {
        const date = new Date(pointRecord.created_date);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const dateTime = `${year}/${month}`;

        let data = returnedData.find(g => g.dateTime === dateTime);
        if (!data) {
          data = { dateTime, records: [] };
          returnedData.push(data);
        }
        
        data.records.push(pointRecord);
      });
    console.log(returnedData);
    return returnedData;
  }

  get gPointCount() {
    return this.prizeRedemptionRecords.filter(
      (record) => record.prize_deduction_point_type === PointRecordType.GPoint
    ).length;
  }
  userPointInfo: UserPointInfoQueryData = {};
  prizeRedemptionRecords: PrizeRedemptionRecordQueryData[] = [];
  nowRedemptionCategerySelected = "PRIZE";
  nowExpiredCategerySelected = "NOT_EXPIRED";

  constructor(
    private loadingService: LoadingService,
    private httpService: HttpService
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    await this.getUserPointInfo()
      .pipe(mergeMap(() => this.getPrizeRedemptionRecords()))
      .toPromise();
    this.loadingService.hide();
  }

  getUserPointInfo() {
    return this.httpService
      .HttpGet("service/activity/getUserPointInfo")
      .pipe(
        tap(
          (response: { message: string; data: UserPointInfoQueryData }) =>
            (this.userPointInfo = response.data)
        )
      );
  }

  getPrizeRedemptionRecords() {
    let url =
      "service/activity/getPrizeRedemptionRecords?redemptionCategery=" +
      this.nowRedemptionCategerySelected +
      "&&expiredCategery=" +
      this.nowExpiredCategerySelected;
    return this.httpService
      .HttpGet(url)
      .pipe(
        tap(
          (response: {
            message: string;
            data: PrizeRedemptionRecordQueryData[];
          }) => (this.prizeRedemptionRecords = response.data)
        )
      );
  }

  async setPrizeCategory(category) {
    this.nowRedemptionCategerySelected = category;
    this.nowExpiredCategerySelected = category === "PRIZE" ? "NOT_EXPIRED" : "";
    // console.log(this.nowRedemptionCategerySelected);
    // console.log(this.nowExpiredCategerySelected);
    this.loadingService.show();
    await this.getUserPointInfo()
      .pipe(mergeMap(() => this.getPrizeRedemptionRecords()))
      .toPromise();
    this.loadingService.hide();
  }

  async setPrizeExpiredCategory(category) {
    this.nowExpiredCategerySelected = category;
    // console.log(this.nowRedemptionCategerySelected);
    // console.log(this.nowExpiredCategerySelected);
    this.loadingService.show();
    await this.getUserPointInfo()
      .pipe(mergeMap(() => this.getPrizeRedemptionRecords()))
      .toPromise();
    this.loadingService.hide();
  }

  getPrizeClass(category) {
    return {
      "subtitle-selected": category === this.nowRedemptionCategerySelected,
      "subtitle-unselected": !(category === this.nowRedemptionCategerySelected),
    };
  }

  getPrizeExpiredClass(category) {
    return {
      "Gpoint-categary-selected": category === this.nowExpiredCategerySelected,
      "Gpoint-categary-unselected": !(
        category === this.nowExpiredCategerySelected
      ),
    };
  }

  getPrizeStatus(item) {
    if (item.serial_type === "LOTTERY_TICKET") {
      switch (item.lottery_serial_status) {
        case "IS_WINNING":
          return "中獎";
        case "NOT_WINNING":
          return "未中獎";
        case "NOT_YET_DRAWN":
          return item.lottery_draw_show_times ? "陸續開獎" : "尚未開獎";
        default:
          return null;
      }
    }
    if (
      item.serial_type === "INTERNAL_REDEMPTION" ||
      item.serial_type === "INTERNAL_LOTTERY_PRIZE" ||
      item.serial_type === "EXTERNAL_REDEMPTION" ||
      item.serial_type === "EXTERNAL_LOTTERY_PRIZE"
    ) {
      return item.serial_status ? "已領取" : "未領取";
    }
    return null;
  }

  getSerialStatusClass(item) {
    return {
      "color-1": "IS_WINNING" === item.lottery_serial_status, // 藍綠色
      "color-a":
        "NOT_WINNING" === item.lottery_serial_status ||
        item.serial_status === true, // 灰色
      "color-2":
        "NOT_YET_DRAWN" === item.lottery_serial_status ||
        item.serial_status === false, // 橘色
    };
  }

  isShowStatus(item) {
    if (
      item.serial_type !== "EXTERNAL_REDEMPTION" &&
      item.serial_type !== "EXTERNAL_LOTTERY_PRIZE"
    ) {
      return true;
    }
    return false;
  }

  isShowPrice(item) {
    if (
      item.serial_type === "EXTERNAL_LOTTERY_PRIZE" ||
      item.serial_type === "INTERNAL_LOTTERY_PRIZE"
    ) {
      return true;
    }
    return false;
  }
}
