import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UserInfo } from '../_models/UserInfo';
import { HttpService } from '../_services/http.service';
@Component({
  selector: 'app-teacher-layout',
  templateUrl: './teacher-layout.component.html',
  styleUrls: ['./teacher-layout.component.css']
})
export class TeacherLayoutComponent implements OnInit {
  userInfodetail: UserInfo;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService) { this.getUserInfo(); }

  ngOnInit() {
  }
  public getUserInfo( ){
    this.userInfodetail=null;
    let url = 'service/getUserInfo';
    this.httpService.HttpGet(url).
    subscribe((response: UserInfo) => {
      this.userInfodetail=response;
  
      console.log(this.userInfodetail);
  
    }, err => {
    });
  
   }
   openNav() {
    document.getElementById("sidemenu").style.display = "inline-table";
  }
  logout(){
    //@ts-ignore
    if (!!window.ReactNativeWebView)
      // app的webview模式專用關閉網址
      window.location.replace('https://cooc.tp.edu.tw/app/close');
    else
      // 登出後回酷課雲首頁
      window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
  }
}
