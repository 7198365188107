import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common'
import { FormsModule } from '@angular/forms'; // 有雙向綁定[(ngModel)]要加FormsModule   使用表單驗證(Moder Driven)要加 ReactiveFormsModule
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { LogoutComponent } from './shard/logout/logout.component';
import { LayoutComponent } from './_layout/_header/layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { MainComponent } from './main/main.component';
import { ShopsItemComponent } from './shops-item/shops-item.component';
import { ShopDetailComponent } from './shop-detail/shop-detail.component';
import { ParsentLayoutComponent } from './parsent-layout/parsent-layout.component';
import { SiteParsentLayoutComponent } from './site-parsent-layout/site-parsent-layout.component';
import { SiteParsentMainComponent } from './site-parsent-main/site-parsent-main.component';
import { TeacherLayoutComponent } from './teacher-layout/teacher-layout.component';
import { SiteTeacherLayoutComponent } from './site-teacher-layout/site-teacher-layout.component';
import { ActivityDetailComponent } from './activity-detail/activity-detail.component';
import { PointRecordHistoryComponent } from './point-record-history/point-record-history.component';
import { PrizeDetailComponent } from './prize-detail/prize-detail.component';
import { PrizeRedemptionHistoryComponent } from "./prize-redemption-history/prize-redemption-history.component";

import { LoadingComponent } from './loading.component';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LayoutComponent,
    SiteLayoutComponent,
    ShopsItemComponent,
    ActivityDetailComponent,
    PointRecordHistoryComponent,
    PrizeRedemptionHistoryComponent,
    PrizeDetailComponent,
    ShopDetailComponent,
    ParsentLayoutComponent,
    SiteParsentLayoutComponent,
    SiteParsentMainComponent,
    TeacherLayoutComponent,
    SiteTeacherLayoutComponent,
    MainComponent,
    LoadingComponent
  ],
  entryComponents: [LoadingComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
